<template lang="html">
  <div class="w-full relative mt-5">
    <vue-perfect-scrollbar class="overflow-auto min-h-full map-area w-full bg-white flex items-center justify-start" ref="mapScroll">
      
      <div class="flex relative justify-center items-center" v-resize="setDimensions" :style="{'min-width': width + 'px','min-height': height + 'px'}">
        <pinch-zoom
          disableZoomControl="disable"
          :wheel="false"
          setZoom="setZoom"
          :draggableImage="true"
          :limitPan="true"
          transitionDuration="400"
          ref="pinchZoom"
          backgroundColor="rgba(0, 0, 0, 0)"
          listener="mouse"
        >
          <img :src="link" alt="" class="select-none" />

          <div 
            v-for="(dItem, idx) in deviceList" :key="idx"
            class="absolute" :style="{left:dItem.pointX, top:dItem.pointY}"
            :id="`map_${idx}`"
          >
            <DropdownAlertViewer
              :pointX="dItem.pointX" 
              :pointY="dItem.pointY" 
              :deviceGuid="dItem.deviceGuid" 
              :detectCount="dItem.detectCount"
              :mapImgWidth="width" 
              :mapImgHeight="height" 
              :index="idx" 
              :deviceStatus="dItem.deviceStatus"
            ></DropdownAlertViewer>
          </div>
        </pinch-zoom>
      </div>
    </vue-perfect-scrollbar>

    <div class="zoom-btn-box absolute top-10 right-10">
      <button type="button" class="btn-zoom plus" @click="zoomin()" ><span class="for-a11y">확대</span></button>
      <button type="button" class="btn-zoom minus" @click="zoomout()"><span class="for-a11y">축소</span></button>
    </div>
  </div>
 
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters } from "vuex";
import DropdownAlertViewer from "@/components/elements/DropdownAlertViewer"

export default {
  props: {
    fileGuid: String,
    mapGuid: String,
    siteGuid: String,
    companyGuid: String,
    checkFlag: Number
  },
  components: {
    DropdownAlertViewer
  },
  data () {
    return {
      link: '',
      width: 1100,
      height: 800,
      baseWidth: 1100,
      baseHeight: 1000,
      deviceList: [],
      scale: 0,
      scaleArray: [1,1.2,1.6,2.2]
    }
  },
  created () {
    this.getImageData()
    this.getHomeDeviceList()

    // console.log(document.getElementById("main-screen").clientWidth)
    // console.log(document.getElementById("main-screen").clientHeight)
  },
  computed: {
    ...mapGetters(["getOnlineDevice","getOfflineDevice"])
  },
  methods: {
    setDimensions () {
    },
    zoomin () {
      if (this.scale < 4) this.scale++;
      this.$refs.pinchZoom.ivyPinch.setZoom({ scale: this.scaleArray[this.scale], center: [0,0] });
      // console.log(document.getElementById("main-screen").clientWidth, this.width, this.$refs.pinchZoom.ivyPinch)

      // if(document.getElementById("main-screen").clientWidth > this.width) {
      //   this.width = this.baseWidth + (100 * this.scale)
      //   this.height = this.baseHeight + (100 * this.scale)
      // }

    },
    zoomout () {
      if (this.scale > 0) this.scale--;
      this.$refs.pinchZoom.ivyPinch.setZoom({ scale: this.scaleArray[this.scale] });
      this.$refs.pinchZoom.ivyPinch.centeringImage()
      if(this.scale == 0) this.$refs.pinchZoom.ivyPinch.resetScale()
      // console.log(document.getElementById("main-screen").clientWidth, this.width, this.scaleArray[this.scale])

      // if(document.getElementById("main-screen").clientWidth > this.width) {
      //   this.width = this.baseWidth + (100 * this.scale)
      //   this.height = this.baseHeight + (100 * this.scale)
      // }
    },
    async getImageData () {
      let reqObj = {}
      reqObj.target = `/files/img/${this.fileGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};

      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      this.link = result.msg.retData

      let img = new Image();
      let self = this;
      img.onload = function () {
        if(self.baseWidth < this.width) self.width = this.width;
        if(self.baseHeight < this.height) self.height = this.height;

        self.$refs.pinchZoom.ivyPinch.setZoom({ scale: 1, center: [0,0] });

        // self.baseWidth = this.width;
        // self.baseHeight = this.height;

      };
      img.src = this.link;

      // setTimeout(() => {
      //   self.mapImgWidth = this.width
      //   self.mapImgHeight = this.height
      // }, 100)

      setTimeout(() => {
        this.$emit("imageOnLoad", this.width, this.height)
      }, 100)
      
    },
    async getHomeDeviceList () {
      let reqObj = {}
      reqObj.target = `/home/devicelist/${this.mapGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceList = result.msg.retData;
      }
    }
  },
  watch: {
    'fileGuid': function () {
      this.getImageData()
    },
    'mapGuid': function() {
      this.getHomeDeviceList()
    },
    'checkFlag': function () {
      this.getHomeDeviceList()
    },
    'getOnlineDevice': function () {
      let deviceGuid = this.getOnlineDevice.split("|@|")[0]

      if(this.deviceList.findIndex(x => x.deviceGuid == deviceGuid) > -1) this.getHomeDeviceList()
    },
    'getOfflineDevice': function () {
      let deviceGuid = this.getOfflineDevice.split("|@|")[0]

      if(this.deviceList.findIndex(x => x.deviceGuid == deviceGuid) > -1) this.getHomeDeviceList()
    }
  }
}
</script>

<style scoped>
.pinch-zoom-wrapper {
  display: contents !important;
}
</style>